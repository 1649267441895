import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Platform } from 'libs/utils/constants';
// import { environment } from '../../../../libs/environment/environment';

@Component({
  selector: 'lv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = `Team Manager by ${Platform}`;

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'sorting',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/icons-sort.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'leaf',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-leaf.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'leafBlack',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-leaf-black.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'aside',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-sidebar.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'verticalMenu',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-more-horizontal.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'add-user',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-user-plus.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'trash',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-trash-2.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'trash-can',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-trash.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'document',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-file.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'filter',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons/a-icons-filter.svg'
      )
    );

    // console.log('Release version : ', environment.release);
    // console.log('Environment version : ', environment.envName);
  }
}
